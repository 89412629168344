import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { PostHeader } from "../layout/PostHeader"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { JobVacancy as IJobVacancy } from "../types/JobVacancy"
import { JobTable } from "../layout/DatoCmsContent/sections/JobTable"
import styled from "styled-components"
import { Favicon } from "../components/Favicon"

const TableComponent = styled.div`
  padding-top: 82px;
  padding-bottom: 70px;
`

const JobVacancy: React.FC<JobVacancyData> = ({ data: { vacancy } }) => (
  <article>
    <PostHeader
      image={vacancy.headerImage}
      imageStyle={"round"}
      showSocialMediaLinks={false}
      {...vacancy}
    />
    {vacancy.content.map((content, i) => (
      <DatoCmsContent
        //@ts-ignore
        key={content?.id}
        verticalMargins={"article"}
        before={i > 0 ? vacancy.content[i - 1] : undefined}
        after={vacancy.content[i + 1] || undefined}
        {...content}
      />
    ))}
    {vacancy.relatedVacancies && vacancy.relatedVacancies.length > 0 && (
      <JobTable
        __typename={"DatoCmsJobTable"}
        id={"related"}
        title={vacancy.relatedVacanciesLabel}
        vacancies={vacancy.relatedVacancies}
        //@ts-ignore
        as={TableComponent}
      />
    )}
    <HelmetDatoCms seo={vacancy.seoMetaTags} />
    <Favicon />
  </article>
)

export default JobVacancy

type JobVacancyData = {
  data: {
    vacancy: IJobVacancy & { seoMetaTags: any }
  }
}

export const query = graphql`
  query($id: String) {
    vacancy: datoCmsJobVacancy(id: { eq: $id }) {
      ...JobVacancyData

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
